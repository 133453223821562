.bg-brand-select {
  background: rgb(54,54,56);
  background: -moz-linear-gradient(145deg, rgba(54,54,56,1) 0%, rgba(46,44,46,1) 50%, rgba(38,34,36,1) 100%);
  background: -webkit-linear-gradient(145deg, rgba(54,54,56,1) 0%, rgba(46,44,46,1) 50%, rgba(38,34,36,1) 100%);
  background: linear-gradient(145deg, rgba(54,54,56,1) 0%, rgba(46,44,46,1) 50%, rgba(38,34,36,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#363638",endColorstr="#262224",GradientType=1);
}

.form-signin {
  width: 100%;
  width: 550px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}